@import "../variables";

.main {
  .settings {
    &-menu {
      background-color: $white;
      width: 23%;
      padding-bottom: 14rem;
      border-right: 2px solid $settings-border;
    }

    &-main {
      background-color: $white;
      width: 77%;
      flex-grow: 1;
      // padding: 1.75rem 1.5rem;

      &-notify {
        font-weight: 600 !important;
      }

      label {
        color: $form-label;
        font-size: 18px;
      }

      input {
        width: 60%;
        border: 1px solid $input-border;
        border-radius: 6px;
        background-color: $white;
        padding: 0.65rem;
      }

      .disable {
        &-group {
          p {
            font-weight: 500;
            font-size: 18px;

            .email-text {
              color: $primary-brand;
            }

            .link-text {
              color: #000000;
              text-decoration: none;
              border-bottom: 1px solid #000000;

              &:hover {
                border-bottom: none;
              }
            }
          }

          .warn-text {
            color: $settings-text-warn;
          }
        }

        &-form {
          label {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }

      .account-name-text {
        color: $settings-success;
        font-weight: 500;
      }

      .accounts-group {
        border-top: 1px solid $settings-border;

        h2 {
          color: $form-label;
          font-weight: 600;
          margin: 1.35rem 0;
        }

        &-item {
          color: $form-label;
          border-bottom: 1px solid $settings-border;

          &:last-child {
            border: none;
          }

          .acc-name {
            font-weight: 600;
          }

          span {
            font-size: 14px;
          }
        }
      }

      .check-area {
        border: 1px solid silver;
        padding: 2rem;
        border-radius: 8px;
        justify-content: space-between;

        .check-box {
          display: flex;
          flex-direction: column;

          input {
            width: 1rem;
            height: 1rem;
            margin-bottom: 3.1rem;
            background-color: $form-field !important;
            border: 1px solid $input-border !important;

            &:focus, &:active, &:checked {
              background-color: $white;
              color: $primary-brand;
              border: 1.9px solid $primary-brand;
              box-shadow: $primary-brand;
            }
          }
        }
      }
    }
  }
}
