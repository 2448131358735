@import "../variables";

.navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%280, 0, 0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 11h22M4 17h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
  border: none;
  padding: 0;

  &:focus {
    box-shadow: none;
  }
}

%formpseudo {
  input:focus, select:focus {
    outline: none;
  }
}

.dropdown-menu.show {
  display: block;
  left: -3rem;
  top: 0.75rem
}
