@import "../variables";

.react-datepicker {
  font-family: Helvetica, "sans-serif";
  font-size: 1rem !important;
}

.react-datepicker__current-month {
  color: rgb(72, 72, 72) !important;
  margin-bottom: 1rem;
}

.react-datepicker-time__header {
  color: rgb(72, 72, 72) !important;
  font-size: 1rem !important;
}

.react-datepicker-year-header {
  color: rgb(72, 72, 72) !important;
}

.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: none !important;
}

.react-datepicker__day--selected {
  border-radius: 0 !important;
}

.react-datepicker__input-container {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 6px;

  input {
    width: 100%;
    border: none !important;
  }
}

.react-datepicker__year-select {
  padding: 0.2rem 0.5rem;

}

.react-datepicker__navigation {
  top: 15px !important;
}

.react-datepicker__navigation--previous {
  background: url(../../images/svg/nav-left-arrow.svg) no-repeat;
  width: 40px;
  height: 30px;
  margin: 0 0 0 20px;
}

.react-datepicker__navigation--next {
  background: url(../../images/svg/nav-right-arrow.svg) no-repeat;
  width: 40px;
  height: 30px;
  margin: -2px 0 0 20px;
}

.react-datepicker__navigation-icon {
  &::before {
    border-style: none;
  }
}

.custom {
  .react-datepicker__input-container {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000000 !important;
    border-color: transparent transparent #000000;;
  }
}