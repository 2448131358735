@import "../variables";

.profile{

  &-header{
    border-bottom: 1px solid #DEDEDE;
  }

  &-info-name{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }

  &-info-deet{
    font-size: 15px;
  }
}

.media{
  position: relative;
  .main-img{
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
  .overlay_img{
    position: absolute;
    bottom: 1px;
    right: 2px;
    width: 32px;
    height: 32px;
    color: $white;
    background: $primary;
    outline: none;
    border: none;

    &:hover, &:focus {
      background: $primary-hover;
    }
  }
}


.profile-vehicle-image{

  img{
    max-height: 400px;
    object-fit: contain;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border: 1px solid #C0C0C0;
    background-color: #FFFFFF;
  }
  .card-body{
    flex: 1 1 auto;
    padding: 0 20px;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    .card-text {
      width: 100%;
      color: $primary-text;
    }
  }
}
