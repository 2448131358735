@import "../variables";
@import "../util/mixins";

.accordion-button {
  border-radius: 5px;

  &:first-child {
    border-top: 0.3px solid $primary;
  }
}


.accordion-item {
  outline: none;
  margin-bottom: 15px;
  border-radius: 5px;

  &:not(.collapsed) {
    border-bottom: 0.3px solid $primary;
    border-left: 0.3px solid $primary;
    border-right: 0.3px solid $primary;
  }
}

.btn {
  border-radius: 6px;
  border: none;
  padding: 0.95rem 2.75rem;
  font-size: 16px;
  font-weight: 300;
}

.btn-sm {
  border-radius: 4px;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 12px;
  font-weight: 200;
}

.main-btn,
.primary-btn,
.btn-primary,
.btn-primary.btn.show {
  background-color: $primary-bg;
  color: $primary-text;

  &:disabled {
    background-color: $primary-disabled;
    color: $primary-text;
  }

  &:hover,
  &:focus {
    background-color: $primary-hover;
    color: $primary-text;
  }
}

.secondary-btn {
  background-color: $secondary-bg;
  color: $secondary-text;

  &:disabled {
    background-color: $secondary-disabled;
  }

  &:hover {
    background-color: $secondary-hover;
    color: $secondary-text;
  }
}

.info-btn {
  background-color: $info-bg;
  color: $info-text;

  &:disabled {
    background-color: $info-disabled;
  }

  &:hover {
    background-color: $info-hover;
    color: $info-text;
  }
}

.btn-danger {
  color: $white;
  background-color: $danger;
  border-color: $danger;

  &:hover {
    color: $white;
    background-color: #f53729;
    border-color: #f53729;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: $danger;
    border-color: $danger;
  }

  &:not(:disabled):not(.disabled) {

    &:active,
    &.active {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }
}

.input-group .btn {
  position: relative;
  padding: 10px 12px;
  height: 2.9rem;
  background-color: $primary;
}


.trip-btn {
  padding: 0.95rem 0.8rem;

  @include lg {
    width: 32%;
  }
}