@import "../variables";
@import "../util/mixins";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border-radius: 10px;
}

.main-signin-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

.card-width {
  width: 35%;
  border-radius: 10px !important;
  @include sm {
    width: 75%;
  }
  @include md {
    width: 75%;
  }
  @include lg {
    width: 60%;
  }
  @include xl {
    width: 40%;
  }
}

.text-sm-center .form-control {
  line-height: 0;
}

.form-control {
  line-height: 2;
  border: 1px solid #C0C0C0;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 0.65rem;

  &:focus {
    border: 1.9px solid $primary;
    box-shadow: none;
  }

  &:disabled, &[readonly] {
    opacity: 1;
    background-color: lighten($primary, 58%);
  }

  &.is-invalid{
    border: 1px solid $danger;
    background: none;

    &:focus {
      box-shadow: none;
    }
  }
}



%custom-form {
  h5 {
    color: $primary-brand;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  > div {
    margin-top: 1rem;
  }

  input {
    background-color: $form-field;
    border: 1px solid #c0c0c0;

    &:focus {
      border: 1.9px solid $primary-brand;
      box-shadow: none;
    }
  }

  .log-in {
    background-color: $primary-brand;
    color: #fff;
    width: 90%;
    border: none;
    line-height: 2.5;
    border-radius: 4px;
    margin: 2rem 0 1rem 0;

    &:disabled {
      background-color: $primary-disabled;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .log-in-google {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid $primary-brand;
    width: 80%;
    line-height: 0;
    padding: 0.6rem;

    span p {
      color: #6a6969;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0;
      margin-left: 10px;
    }

    span img {
      width: 8%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  label {
    color: #6a6969;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }

  #or {
    color: #6a6969;
    font-weight: 500;
    font-size: 0.7rem;
  }

  .signup {
    color: $primary-brand;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;

  }
}

.login {
  &-otpform {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @extend %custom-form;

    h5 {
      font-weight: 700;
      font-size: 2rem;
      color: #333333;
    }

    #or {
      font-size: 0.8rem;
      color: #333333;
    }
  }

  &-form {
    @extend %custom-form

  }
}

%page-loader {
  height: 5px;
  background-image: linear-gradient(to right, $primary-brand 50%, #fff 50%);
  margin-top: 0.1rem !important;
  border: 1px solid #b6b4b4;
  margin-bottom: 1rem;
}

.signup-page-step-loader {
  @extend %page-loader;
}

.signup-page-step-loader2 {
  @extend %page-loader;
  background-image: none;
  background-color: $primary-brand;
}


.phone-container {
  display: inline-block;
  position: relative;

}

#phone-domicile {
  text-align: initial;
}

#phone-domicile label {
  margin-bottom: 0 !important;
}

#phone-domicile .span-container {
  background-color: #f7f7f7;
  width: 20%;
  border: 1px solid silver;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 9px;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-right: none !important;

  &:active {
    border: 1px solid $primary-brand;
  }
}

.phone-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;

  &:focus {
    #phone-domicile .span-container {
      border: 1px solid $primary-brand;
    }
  }
}

#phone-domicile .span-rule {
  height: 1rem;
  width: 0.2px;
  background-color: #b6b4b4;
}

.phone-country {
  width: 55%;
  height: 1.3rem;
}

.signup-container .next-step {
  display: none;
}

.signup-container {
  .next-step {
    &.show {
      display: block;
    }
  }
}

.hide {
  display: none;
}

.terms-condition {
  display: flex;
  gap: 15px;

  input {
    height: 1.5rem;
    width: 1rem;
    border-radius: 0 !important;
  }

  p {
    color: $primary-brand;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 1rem;
  }

}

.segmented-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 85%;
}

.segmented-input input {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 20px;
  text-align: center;
}

.address-dropdown {
  position: absolute;
  top: 400px;
  border-radius: 6px;
  border: 1px solid #CACACA;
  background-color: #ffffff;
  z-index: 65;
}

.address-dropdown .item {
  font-size: 22px;
  font-weight: 300;
  border-bottom: 1px solid #E1E1E1;
}

