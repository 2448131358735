@import "variables";

.eye-right {
  font-size: 15px;
  color: #828996;
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 25px;
  bottom: 10px;
  cursor: pointer;

  &.extended {
    z-index: 5;
    right: 40px;
  }
}

.delete-right {
  font-size: 15px;
  color: #828996;
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 15px;
  bottom: 8px;
  cursor: pointer;

  &.extended {
    z-index: 5;
    right: 40px;
  }
}

.pointer-event {
  cursor: pointer;
}

.text-right {
  text-align: end !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.google-button {
  width: 28px;
  height: 25px;
  padding-right: 10px
}

.table > :not(:first-child) {
  border-top: 0.2px solid black !important;
}

.loader-modal {
  padding: 20px;
  position: absolute;
  top: 200px;
  width: 95%;
  right: 2%;
  height: auto;
  z-index: 1;
}

.openModal:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  content: "";
}
.description-box{
  width: 100%;
  min-height: 130px;
  border-radius: 5px;
  padding: 15px 10px;
  border: 1px solid $primary-brand;
  background: lighten($primary-brand, 61%);
}

.remove-ml-media-body {
  margin-left: 0 !important;
}

.media-body .tx-medium span.badge.bg-pill {
  display: inline;
  font-size: 10px;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.alert-note {
  font-size: 12px;
  color: #ff0000;
}

.vertical-line{
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: 0 10px;
  height: 75vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.browse-file input[type="file"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  right: 0;
  opacity: 0;
}

.dropdown-toggle::after, .dropdown-toggle::before{
  display: none !important;
}
