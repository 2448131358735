@media (max-width: 992px) {
  .header {
    padding-left: 35px;
    padding-right: 35px;

    &-end {
      width: 100%;

      &-profile {
        border: none;
        order: 1;

        p {
          color: #333333;
        }

        .role-text {
          font-style: normal;
          font-weight: bold;
        }
      }

      &-notif {
        order: 2;
        margin-left: auto;
      }

      .navbar-toggler {
        display: flex;
        order: 3;
      }

    }

  }

  .sidebar {
    left: auto;
    margin-top: 0;
  }
  .app-wrapper {
    margin-left: 0;
  }
  .booking {
    padding-left: 65px;

    &-direct {
      left: 35px;
    }
  }
}

@media (max-width: 992px) {
  .header {
    border: none;
    padding: 1rem 3rem 0.5rem;
  }
  .app-wrapper {
    background-color: #ffffff;
  }
  .main {

    &-btn {
      width: 30%;
    }

    &-table {
      .table-bod {
        border: 2px solid #DFE0EB;
        border-radius: 3px;
      }

      &-data {
        tbody {
          tr {

            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

  }

}

@media (max-width: 992px) {
  .header {
    padding: 1rem;
    margin-bottom: 2rem;
    height: 7rem !important;
  }
  .main {
    &-btn {
      width: 50%;
    }
  }
  .booking {
    &-direct {
      margin-top: 10px;
    }

    &-title {
      position: absolute;
      top: 100px;
    }

    &-form {
      padding-top: 70px;

      .main-btn {
        width: 45%;
      }
    }
  }
  .notifications {
    .dropdown-menu.show {
      left: -8.5rem;
    }
  }
}

@media (max-width: 870px) {
  .main {
    .settings {
      &-sidebar {
        width: 17%;

        .sidebar-menu-item {
          a {
            flex-direction: column !important;
            padding: 0.9rem 0.5rem;
            text-align: center;

            #settings-item {
              font-size: 12px !important;
            }
          }
        }
      }

      &-main {
        width: 83%;
      }
    }
  }
}

@media(max-width: 836px) {
  .activate-pin {
    width: 100%;
  }
  .main-table .opt-bg {
    width: 3rem;
    height: 3rem;
  }
  .main-table .process p {
    font-size: 0.8rem;
  }
  .input-group {
    width: 100% !important;
    //padding: 1rem;
  }
  .booking {
    &-confirm {
      padding: 1.5rem 2rem;
    }
  }
}

@media(max-width: 770px) {
  .booking {
    &-btn-group {
      width: 85%;
    }

    &-confirm {
      padding: 1.5rem 1rem;
    }
  }
}


@media (max-width: 695px) {
  .booking {
    padding-right: 1rem;

    &-btn-group {
      width: 100%;
    }
  }
}

@media (max-width: 595px) {
  .booking {
    padding-left: 1rem;

    &-direct {
      left: 0.35rem;
    }

    &-form {
      .grid-group {
        grid-template-columns: auto;
      }
    }

    button {
      &.main-btn {
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .main {
    &-table {
      &-data {
        width: 700px;
      }
    }

    .settings {
      &-main {
        .account-name-text {
          font-size: 13px;

          img {
            width: 16px;
          }
        }

        input, select {
          padding: 0.35rem;
          line-height: 0.2;
          font-size: 11px;
        }

        .accounts-group-item {
          .acc-name {
            font-size: 14px;
          }

          img {
            width: 32px;
          }

          span {
            font-size: 11px;
          }
        }

        .disable-group {
          p {
            font-size: 14px;
          }
        }
      }
    }

    .profile {
      &-table {
        width: 700px;
      }
    }
  }
  .sidebar {
    width: 55% !important;
  }
  .logo {
    img {
      width: 50px;
    }
  }
  .card-width {
    width: 85%;
  }
  .login-form h5, .login-otpform h5 {
    font-size: 1rem;
  }
  .login-form label, .login-otpform label {
    font-size: 0.8rem;
  }
  .form-control {
    font-size: .95rem !important;
  }
  .login-form .log-in, .login-otpform .log-in {
    line-height: 1.7rem;
    font-size: 12px;
    width: 70%;
  }
  .login-form .log-in-google, .login-otpform .log-in-google {
    line-height: 0;
    padding: 0.3rem;
    width: 71%;
  }
  .segmented-input input {
    width: 16px;
    height: 20px;
  }
  .login-form .log-in-google span img, .login-otpform .log-in-google span img {
    width: 8%;
  }
  .eye-right {
    bottom: 17px !important;
  }
  .notification-header {
    font-size: 14px;
    font-weight: 600;
  }
  .notification-description {
    font-size: 14px;
  }
  .notifications {
    .dropdown-menu.show {
      left: -10rem;
    }
  }
}

@media (max-width: 510px) {
  .booking {
    &-nav {
      width: 20%;
    }
  }
}

@media(max-width: 460px) {
  .main {
    .settings {
      &-sidebar {
        width: 19%;

        .sidebar-menu {
          margin-top: 17px;

          &-menu-item {
            a {
              padding: 0.9rem 0;

              #settings-item {
                font-size: 8px !important;
              }

              img {
                width: 0.5rem;
              }
            }
          }
        }
      }

      &-main {
        width: 81%;
      }
    }

    #notifications {
      #notifications-mobile {
        padding: 0.8rem;

        .settings-main-notify {
          font-size: 12px !important;
        }
      }
    }

    .settings-main label {
      font-size: 12px !important;
    }

    .settings-main .check-area .check-box2 input {
      width: 0.7rem !important;
    }

    .check-box2 p {
      font-size: 11px !important;
    }

    .main-btn {
      padding: 0.5rem 0.75rem;
      font-size: 11px;
    }

    &-table {
      .process {
        padding: 2rem 3rem;
      }

      .nodata-group {
        padding: 0.7rem;

        span {
          font-size: 11px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .btns-sm {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    .main-btn {
      width: 100%;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
  .main {
    .settings {
      &-sidebar {
        .sidebar-menu-item {
          a {
            #settings-item {
              font-size: 10px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .booking {
    &-nav {
      width: 25%;
    }
  }
}
